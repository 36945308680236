import React from 'react';
import { formatCurrency } from '../../utils/formatters';
import { ArrowUpIcon, ArrowDownIcon } from '@heroicons/react/24/solid';

const CryptoMarket = ({ cryptos, isLoading, isError }) => {
  if (isError) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-4 text-custom-blue">Top 5 Cryptomonnaies</h2>
        <p className="text-red-500">Erreur de chargement des données</p>
      </div>
    );
  }

  if (isLoading) {
    return (
      <div className="bg-white rounded-lg shadow-md p-6">
        <h2 className="text-2xl font-bold mb-4 text-custom-blue">Top 5 Cryptomonnaies</h2>
        <div className="animate-pulse">
          {[...Array(5)].map((_, i) => (
            <div key={i} className="h-12 bg-gray-200 rounded mb-2"></div>
          ))}
        </div>
      </div>
    );
  }

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4 text-custom-blue">Top 5 Cryptomonnaies</h2>
      <div className="overflow-hidden">
        <table className="min-w-full">
          <thead>
            <tr className="border-b">
              <th className="text-left py-3">Crypto</th>
              <th className="text-right py-3">Prix</th>
              <th className="text-right py-3">24h</th>
            </tr>
          </thead>
          <tbody>
            {cryptos.map((crypto, index) => (
              <tr key={index} className="border-b">
                <td className="py-3">
                  <div className="flex items-center">
                    <img src={crypto.icon} alt={crypto.name} className="w-6 h-6 mr-2" />
                    <span className="font-medium">{crypto.name}</span>
                  </div>
                </td>
                <td className="text-right py-3">{formatCurrency(crypto.price)}</td>
                <td className="text-right py-3">
                  <span className={`flex items-center justify-end ${
                    crypto.change24h >= 0 ? 'text-green-600' : 'text-red-600'
                  }`}>
                    {crypto.change24h >= 0 ? (
                      <ArrowUpIcon className="w-4 h-4 mr-1" />
                    ) : (
                      <ArrowDownIcon className="w-4 h-4 mr-1" />
                    )}
                    {Math.abs(crypto.change24h).toFixed(2)}%
                  </span>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CryptoMarket;