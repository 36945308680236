import React from 'react';

const InvestorPerformance = ({ investors }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4 text-custom-blue">Performance des Investisseurs</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        {investors.map((investor, index) => (
          <div key={index} className="p-4 bg-gray-50 rounded-lg">
            <div className="flex justify-between items-center mb-2">
              <p className="font-semibold">{investor.name}</p>
              <span className={`px-2 py-1 rounded-full text-sm ${
                investor.performance >= 0 
                  ? 'bg-green-100 text-green-800' 
                  : 'bg-red-100 text-red-800'
              }`}>
                {investor.performance > 0 ? '+' : ''}{investor.performance}%
              </span>
            </div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div 
                className={`h-2 rounded-full ${
                  investor.performance >= 0 ? 'bg-green-500' : 'bg-red-500'
                }`}
                style={{ width: `${Math.abs(investor.performance)}%` }}
              ></div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InvestorPerformance;