import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Signup({ onButtonClick }) {
  const [showPasswordFields, setShowPasswordFields] = useState(false);
  const [formData, setFormData] = useState({ nom: '', prenom: '', email: '', password: '', confirmPassword: '' });
  const [errors, setErrors] = useState({ nom: '', prenom: '', email: '', password: '', confirmPassword: '' });
  const [isAccountActive, setIsAccountActive] = useState(false);

  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
  
    let currentErrors = { nom: '', prenom: '', email: '', password: '', confirmPassword: '' };
    let valid = true;

    // Validation des champs
    if (!formData.nom) {
      currentErrors.nom = 'Le nom est requis.';
      valid = false;
    }
    if (!formData.prenom) {
      currentErrors.prenom = 'Le prénom est requis.';
      valid = false;
    }
    if (!formData.email) {
      currentErrors.email = 'L\'email est requis.';
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      currentErrors.email = 'L\'email est invalide.';
      valid = false;
    }
  
    if (showPasswordFields) {
      if (!formData.password) {
        currentErrors.password = 'Le mot de passe est requis.';
        valid = false;
      }
      if (formData.password !== formData.confirmPassword) {
        currentErrors.confirmPassword = 'Les mots de passe ne correspondent pas.';
        valid = false;
      }
    }
  
    setErrors(currentErrors);
  
    if (valid) {
      if (!showPasswordFields) {
        setShowPasswordFields(true);
      } else {
        try {
          // Appel à auth.js pour créer un compte utilisateur
          const res = await axios.post('/api/auth/signup', {
            nom: formData.nom,
            prenom: formData.prenom,
            email: formData.email,
            password: formData.password,
          });

          if (res && res.data) {
            console.log(res.data);
            setIsAccountActive(true);
            onButtonClick();
            navigate('/login');
          } else {
            console.error('La réponse du serveur est invalide', res);
          }
        } catch (err) {
          console.error('Erreur lors de la requête API:', err);
  
          if (err.response) {
            setErrors({ ...errors, email: err.response.data.message || 'Erreur lors de la création du compte' });
          } else if (err.request) {
            console.error('Erreur de réseau ou serveur indisponible');
            setErrors({ ...errors, email: 'Le serveur est indisponible, veuillez réessayer plus tard.' });
          } else {
            console.error('Erreur:', err.message);
          }
        }
      }
    }
  };  

  return (
    <div style={styles.page}>
      <div style={styles.container}>
        <h2 style={styles.title}>Rejoignez la communauté Edufin !</h2>
        <p style={styles.subtitle}>
          Déjà inscrit ? <Link to="/login" style={styles.link}>Connectez-vous ici.</Link>
        </p>
        <form style={styles.form} onSubmit={handleSubmit}>
          {!showPasswordFields && (
            <>
              <input
                type="text"
                name="nom"
                placeholder="Nom"
                style={styles.input}
                value={formData.nom}
                onChange={handleChange}
              />
              {errors.nom && <p style={styles.error}>{errors.nom}</p>}

              <input
                type="text"
                name="prenom"
                placeholder="Prénom"
                style={styles.input}
                value={formData.prenom}
                onChange={handleChange}
              />
              {errors.prenom && <p style={styles.error}>{errors.prenom}</p>}

              <input
                type="email"
                name="email"
                placeholder="Email"
                style={styles.input}
                value={formData.email}
                onChange={handleChange}
              />
              {errors.email && <p style={styles.error}>{errors.email}</p>}
            </>
          )}

          {showPasswordFields && (
            <>
              <input
                type="password"
                name="password"
                placeholder="Mot de passe"
                style={styles.input}
                value={formData.password}
                onChange={handleChange}
              />
              {errors.password && <p style={styles.error}>{errors.password}</p>}

              <input
                type="password"
                name="confirmPassword"
                placeholder="Confirmer Mot de passe"
                style={styles.input}
                value={formData.confirmPassword}
                onChange={handleChange}
              />
              {errors.confirmPassword && <p style={styles.error}>{errors.confirmPassword}</p>}
            </>
          )}

          <button type="submit" style={styles.button}>
            {showPasswordFields ? "Soumettre" : "Suivant"}
          </button>
          <Link to="/Contact" style={styles.link}>
            Problèmes d'inscription ?
          </Link>
        </form>
        {isAccountActive && <p style={styles.success}>Votre compte a été activé avec succès !</p>}
      </div>
    </div>
  );
}

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to bottom, #0f2027, #203a43, #2c5364)',
  },
  container: {
    background: '#f7f7f7',
    padding: '20px',
    borderRadius: '8px',
    boxShadow: '0 0 10px rgba(0, 0, 0, 0.1)',
    maxWidth: '400px',
    margin: '50px auto',
    textAlign: 'center',
  },
  title: {
    fontSize: '24px',
    marginBottom: '10px',
    color: '#333',
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: '20px',
    color: '#666',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
    fontSize: '14px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    padding: '10px',
    marginBottom: '10px',
    borderRadius: '4px',
    border: '1px solid #ccc',
    fontSize: '14px',
  },
  button: {
    padding: '10px',
    borderRadius: '4px',
    border: 'none',
    background: '#243EFF',
    color: '#fff',
    fontSize: '16px',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginBottom: '10px',
  },
  success: {
    color: 'green',
    fontSize: '14px',
    marginTop: '20px',
  },
};

export default Signup;
