import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { articleData } from '../Articles/ArticlePage'; // Importez les données des articles
import ArticleCard from '../Articles/ArticleCard'; // Importez le composant ArticleCard
import DOMPurify from 'dompurify'; // Importez DOMPurify
import './SearchResults.css';

// Convertir les données des articles en un tableau
const articles = Object.keys(articleData).map(key => ({
  id: key,
  title: articleData[key].title,
  date: new Date(articleData[key].date), // Convertir la date en objet Date
  author: articleData[key].author,
  content1: articleData[key].content1, // Assurez-vous d'utiliser la bonne clé pour le contenu
  imageUrl: articleData[key].imageUrl1, // Assurez-vous d'utiliser la bonne clé pour l'image
  link: `/articles/${key}`
}));

// Trier les articles par date (du plus récent au plus ancien)
articles.sort((a, b) => b.date - a.date);

const SearchDropdown = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [filteredArticles, setFilteredArticles] = useState(articles);
  const [currentPage, setCurrentPage] = useState(1);
  const articlesPerPage = 5;
  const location = useLocation();

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);
    const query = queryParams.get('query') || '';
    setSearchTerm(DOMPurify.sanitize(query)); // Échapper l'entrée utilisateur

    const filteredResults = articles.filter(article =>
      DOMPurify.sanitize(article.title.toLowerCase()).includes(query.toLowerCase()) ||
      DOMPurify.sanitize(article.content1.toLowerCase()).includes(query.toLowerCase())
    );
    setFilteredArticles(filteredResults);
    setCurrentPage(1); // Réinitialiser à la première page lors de la recherche
  }, [location.search]);

  // Calculer le nombre total de pages
  const totalPages = Math.ceil(filteredArticles.length / articlesPerPage);

  // Obtenir les articles pour la page actuelle
  const indexOfLastArticle = currentPage * articlesPerPage;
  const indexOfFirstArticle = indexOfLastArticle - articlesPerPage;
  const currentArticles = filteredArticles.slice(indexOfFirstArticle, indexOfLastArticle);

  // Fonction pour changer de page
  const paginate = (pageNumber) => {
    setCurrentPage(pageNumber);
    window.scrollTo({ top: 0, behavior: 'smooth' }); // Faire défiler vers le haut de la page
  };

  const handleSearch = (event) => {
    const term = DOMPurify.sanitize(event.target.value.toLowerCase());
    setSearchTerm(term);
    const filteredResults = articles.filter(article =>
      DOMPurify.sanitize(article.title.toLowerCase()).includes(term) || DOMPurify.sanitize(article.content1.toLowerCase()).includes(term)
    );
    setFilteredArticles(filteredResults);
    setCurrentPage(1); // Réinitialiser à la première page lors de la recherche
  };

  return (
    <div className="background">
      <div className="search-dropdown">
        <input
          type="text"
          placeholder="Rechercher..."
          value={searchTerm}
          onChange={handleSearch}
          className="search-input"
        />
        <div className="search-results">
          {currentArticles.length > 0 ? (
            currentArticles.map(article => (
              <ArticleCard
                key={article.id}
                title={DOMPurify.sanitize(article.title)}
                date={article.date instanceof Date ? article.date.toLocaleDateString() : article.date} // Convertir la date en chaîne de caractères
                author={DOMPurify.sanitize(article.author)}
                imageUrl={article.imageUrl}
                content1={DOMPurify.sanitize(article.content1)}
                link={article.link}
              />
            ))
          ) : (
            <p className='error'>Aucun résultat trouvé</p>
          )}
        </div>
        <div className="pagination">
          {Array.from({ length: totalPages }, (_, index) => (
            <button
              key={index + 1}
              onClick={() => paginate(index + 1)}
              className={`page-button ${currentPage === index + 1 ? 'active' : ''}`}
            >
              {index + 1}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SearchDropdown;