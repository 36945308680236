import React, { useEffect, useState } from 'react';

function Counter({ end, duration, prefix = '', suffix = '', className, start }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    if (!start) return;

    let startValue = 0;
    const endValue = parseInt(end, 10);
    const incrementTime = (duration * 1000) / endValue;

    const timer = setInterval(() => {
      startValue += 1;
      setCount(startValue);

      if (startValue === endValue) {
        clearInterval(timer);
      }
    }, incrementTime);

    return () => clearInterval(timer);
  }, [end, duration, start]);

  return (
    <span className={className}>
      {prefix}{count}{suffix}
    </span>
  );
}

export default Counter;