import React from 'react';
import WalletSummary from '../components/Dashboard/WalletSummary';
import AssetDistribution from '../components/Dashboard/AssetDistribution';
import RecentTransactions from '../components/Dashboard/RecentTransactions';
import InvestorPerformance from '../components/Dashboard/InvestorPerformance';
import CryptoMarket from '../components/Dashboard/CryptoMarket';
import StockIndices from '../components/Dashboard/StockIndices';
import { useMarketData } from '../hooks/useMarketData';
import './Dashboard.css'

const Dashboard = () => {
  const { cryptoData, stockData, isLoading, isError } = useMarketData();

  const walletData = {
    totalBalance: '******,**', // Remplacer par la chaîne de caractères
    monthlyChange: 5.3,
    yearlyChange: 12.8,
  };

  const assets1 = [
    { name: 'Bitcoin', value: 5000 },
    { name: 'Ethereum', value: 3000 },
    { name: 'Actions', value: 4000 },
    { name: 'Obligations', value: 2000 },
    { name: 'Cash', value: 1780.42 },
  ];

  const assets2 = [
    { name: 'Bitcoin', value: 5000 },
    { name: 'Ethereum', value: 3000 },
    { name: 'Actions', value: 4000 },
    { name: 'Obligations', value: 2000 },
    { name: 'Cash', value: 1780.42 },
  ];

  const assets3 = [
    { name: 'Bitcoin', value: 5000 },
    { name: 'Ethereum', value: 3000 },
    { name: 'Actions', value: 4000 },
    { name: 'Obligations', value: 2000 },
    { name: 'Cash', value: 1780.42 },
  ];

  const transactions1 = [
    { date: '2024-01-15', type: 'achat', asset: 'Bitcoin', amount: 1000 },
    { date: '2024-01-14', type: 'vente', asset: 'Ethereum', amount: 500 },
    { date: '2024-01-13', type: 'achat', asset: 'Actions', amount: 750 },
    { date: '2024-01-12', type: 'achat', asset: 'Bitcoin', amount: 250 },
    { date: '2024-01-11', type: 'vente', asset: 'Obligations', amount: 300 },
  ];

  const transactions2 = [
    { date: '2024-01-15', type: 'achat', asset: 'Bitcoin', amount: 1000 },
    { date: '2024-01-14', type: 'vente', asset: 'Ethereum', amount: 500 },
    { date: '2024-01-13', type: 'achat', asset: 'Actions', amount: 750 },
    { date: '2024-01-12', type: 'achat', asset: 'Bitcoin', amount: 250 },
    { date: '2024-01-11', type: 'vente', asset: 'Obligations', amount: 300 },
  ];

  const transactions3 = [
    { date: '2024-01-15', type: 'achat', asset: 'Bitcoin', amount: 1000 },
    { date: '2024-01-14', type: 'vente', asset: 'Ethereum', amount: 500 },
    { date: '2024-01-13', type: 'achat', asset: 'Actions', amount: 750 },
    { date: '2024-01-12', type: 'achat', asset: 'Bitcoin', amount: 250 },
    { date: '2024-01-11', type: 'vente', asset: 'Obligations', amount: 300 },
  ];

  const investors1 = [
    { name: 'Hugo Boulet', performance: 100 },
    { name: 'Wallet bourse', performance: -3.2 },
    { name: 'Wallet crypto', performance: 8.7 },
  ];

  const investors2 = [
    { name: 'Baptiste Ferahian', performance: 100 },
    { name: 'Wallet bourse', performance: -3.2 },
    { name: 'Wallet crypto', performance: 8.7 },
  ];

  const investors3 = [
    { name: 'Adrien Moncet', performance: 100 },
    { name: 'Wallet bourse', performance: -3.2 },
    { name: 'Wallet crypto', performance: 8.7 },
  ];

  return (
    <div className="dashboard-background">
      <div className="dashboard-container">
        <h1 className="dashboard-title">Tableau de Bord</h1>
        <p className="dashboard-paragraph">Bienvenue sur votre tableau de bord. Voici un aperçu de votre portefeuille.</p>
        <div className="space-y-6">
          <WalletSummary {...walletData} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <CryptoMarket 
              cryptos={cryptoData || []} 
              isLoading={isLoading} 
              isError={isError} 
            />
            <StockIndices 
              indices={stockData || []} 
              isLoading={isLoading} 
              isError={isError} 
            />
          </div>
          <h2 className="dashboard-subtitle">Performance de nos portefeuilles</h2>
          <InvestorPerformance investors={investors1} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <AssetDistribution assets={assets1} />
            <RecentTransactions transactions={transactions1} />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <InvestorPerformance investors={investors2} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <AssetDistribution assets={assets2} />
            <RecentTransactions transactions={transactions2} />
          </div>
          <br />
          <br />
          <br />
          <br />
          <br />
          <InvestorPerformance investors={investors3} />
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
            <AssetDistribution assets={assets3} />
            <RecentTransactions transactions={transactions3} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Dashboard;