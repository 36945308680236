import React from 'react';
import { formatCurrency } from '../../utils/formatters';

const WalletSummary = ({ totalBalance, monthlyChange, yearlyChange }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6 mb-6">
      <h2 className="text-2xl font-bold mb-4 text-custom-blue">Aperçu du Portefeuille</h2>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="p-4 bg-gray-50 rounded-lg">
          <p className="text-gray-600">Balance Totale</p>
          <p className="text-2xl font-bold">{typeof totalBalance === 'string' ? totalBalance : formatCurrency(totalBalance)}</p>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg">
          <p className="text-gray-600">Variation Mensuelle</p>
          <p className={`text-2xl font-bold ${monthlyChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {monthlyChange > 0 ? '+' : ''}{monthlyChange}%
          </p>
        </div>
        <div className="p-4 bg-gray-50 rounded-lg">
          <p className="text-gray-600">Variation Annuelle</p>
          <p className={`text-2xl font-bold ${yearlyChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
            {yearlyChange > 0 ? '+' : ''}{yearlyChange}%
          </p>
        </div>
      </div>
    </div>
  );
};

export default WalletSummary;