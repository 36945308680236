import React from "react";
import { Link } from "react-router-dom";
import './ArticleCard.css';

// Fonction utilitaire pour supprimer les balises HTML
const stripHtmlTags = (str) => {
  if (!str) return '';
  return str.replace(/<\/?[^>]+(>|$)/g, "");
};

// Fonction utilitaire pour extraire la première phrase
const getFirstSentence = (str) => {
  if (!str) return '';
  const strippedStr = stripHtmlTags(str);
  const firstSentence = strippedStr.match(/[^.!?]*[.!?]/);
  return firstSentence ? firstSentence[0] : strippedStr;
};

const ArticleCard = ({ title, date, author, imageUrl, content1, link }) => {
  return (
    <Link to={link} className="article-card">
      <img src={imageUrl} alt={title} className="article-image" />
      <div className="article-content">
        <h3 className="article-title">{title}</h3>
        <div className="article-meta">
          <span>{date}</span> | <span>{author}</span>
        </div>
        <p className="article-subtitle">{getFirstSentence(content1)}</p>
      </div>
    </Link>
  );
};

export default ArticleCard;