import React from 'react';
import { useNavigate } from 'react-router-dom';
import './Lerning.css';

const Lerning = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/académie');
    window.scrollTo(0, 0); // Fait défiler la page vers le haut
  };

  return (
    <section className="ctaSection">
      <div className="ctaContainer">
        <h1 className="ctaTitle">Rejoignez l'Académie Edufin !</h1>
        <p className="ctaSubtitle">
          L'Académie Edufin offre une variété de cours et de ressources pour vous aider à maîtriser les concepts financiers. Que vous soyez débutant ou expert, nos cours sont conçus pour vous fournir les connaissances et les compétences nécessaires pour réussir dans le monde de la finance.
        </p>
        <button className="primaryButton" onClick={handleRedirect}>
          Aller à l'Académie
        </button>
      </div>
    </section>
  );
};

export default Lerning;