import React, { useEffect, useRef } from 'react';
import './NewsSection.css';
import { Link } from 'react-router-dom';

function NewsSection() {
  const newsItemsRef = useRef(null);

  useEffect(() => {
    const isMobile = window.innerWidth <= 768;

    const options = {
      root: isMobile ? null : null, // Change `null` to `newsItemsRef.current` if needed for specific scrolling container
      rootMargin: '0px',
      threshold: isMobile ? Array.from({ length: 101 }, (_, i) => i / 100) : 0.1,
    };

    const observer = new IntersectionObserver((entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          const ratio = entry.intersectionRatio; // Proportion de visibilité
          if (isMobile) {
            entry.target.style.opacity = ratio;
          } else {
            entry.target.classList.add('visible');
          }
        } else if (!isMobile) {
          entry.target.classList.remove('visible');
        }
      });
    }, options);

    const newsCards = document.querySelectorAll('.news-card');
    newsCards.forEach((card) => observer.observe(card));

    return () => {
      newsCards.forEach((card) => observer.unobserve(card));
    };
  }, []);

  return (
    <section className="news" ref={newsItemsRef}>
      <div>
        <h1 className="ctaTitle">Top News</h1>
      </div>
      <div className="news-items">
        <Link to="/articles/moo-deng-coin" className="news-card no-decoration">
          <img src="/assets/moo-deng-coin1.jpg" alt="Moo Deng Coin" className="image" />
          <p className="top-news-meta">29 Novembre 2024 | Baptise Ferahian</p>
          <h3 className="newsTitle">Moo Deng Coin : Le Nouveau Phénomène des Memecoins</h3>
        </Link>
        <Link to="/articles/hausse-bitcoin-renouveau-ou-mirage" className="news-card no-decoration">
          <img src="/assets/hausse-bitcoin-renouveau-ou-mirage1.jpg" alt="Hausse du Bitcoin" className="image" />
          <p className="top-news-meta">15 Novembre 2024 | Baptiste Ferahian</p>
          <h3 className="newsTitle">Hausse du Bitcoin : Vers un renouveau ou simple mirage pour les cryptomonnaies ?</h3>
        </Link>
        <Link to="/articles/stocks-hausse-obligations-baisse" className="news-card no-decoration">
          <img src="/assets/stocks-hausse-obligations-baisse1.png" alt="les marchés financiers réagissent à la réélection de Donald Trump" className="image" />
          <p className="top-news-meta">5 Novembre 2024 | Hugo Boulet</p>
          <h3 className="newsTitle">Stocks en Hausse, Obligations en Baisse : Réaction des Marchés après la Victoire de Trump</h3>
        </Link>
      </div>
        <div className="ActuButtonContainer">
            <Link to="/searchDropdown" className="ActuSecondaryButton">Voir plus d'articles</Link>
        </div>
    </section>
  );
}

export default NewsSection;
