import React from 'react';
import '../pages/Académie2.css'; // Importez le fichier CSS
import HeroSection from '../components/HeroSection2';

function Académie2() {
  return (
    <div className="page">
      <div className="content">
        <HeroSection />
      </div>
    </div>
  );
}

export default Académie2;