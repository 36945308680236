import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import cryptoIcon from '../assets/cryptoIcon.png';
import bourseIcon from '../assets/bourseIcon.png';
import guideIcon from '../assets/guideIcon.png';
import newsletterIcon from '../assets/newsletterIcon.png';
import { FaGlobe } from 'react-icons/fa';
import logo from '../assets/edufin.png';
import './Navbar.css';

const Navbar = () => {
  const [showInvestDropdown, setShowInvestDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState('');
  const [isBurgerOpen, setIsBurgerOpen] = useState(false);
  const [, setIsSubmenuOpen] = useState(false); // Nouveau état pour le sous-menu dans le burger
  const [dropdownTimeout, setDropdownTimeout] = useState(null);

  const location = useLocation();
  const navigate = useNavigate();

  const handleScrollTo = (sectionId) => {
    if (location.pathname !== '/actualités') {
      navigate('/actualités', { state: { scrollToId: sectionId } });
    } else {
      const element = document.getElementById(sectionId);
      if (element) {
        const offset = window.innerWidth <= 768 ? 100 : 0; // Ajustez cette valeur pour ajuster le décalage sur mobile
        const elementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
        window.scrollTo({ top: elementPosition, behavior: 'smooth' });

        // Vérifier la position de l'élément après un court délai
        setTimeout(() => {
          const newElementPosition = element.getBoundingClientRect().top + window.scrollY - offset;
          if (newElementPosition !== elementPosition) {
            window.scrollTo({ top: newElementPosition, behavior: 'smooth' });
          }
        }, 300); // Ajustez le délai si nécessaire
      }
    }
    setShowInvestDropdown(false);
    setIsBurgerOpen(false);
    setIsSubmenuOpen(false);
  };

  const handleSearchSubmit = (event) => {
    event.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?query=${searchQuery}`);
      setIsBurgerOpen(false); // Ferme le menu burger
    }
  };

  const handleSearchChange = (e) => {
    setSearchQuery(e.target.value);
  };

  const handleMouseEnter = () => {
    if (dropdownTimeout) {
      clearTimeout(dropdownTimeout);
      setDropdownTimeout(null);
    }
    setShowInvestDropdown(true);
  };

  const handleMouseLeave = () => {
    const timeout = setTimeout(() => {
      setShowInvestDropdown(false);
    }, 300); // Délai de 300ms avant de masquer le menu déroulant
    setDropdownTimeout(timeout);
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (!event.target.closest('.navbar__dropdown-container')) {
        setShowInvestDropdown(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className="main-content">
        {isBurgerOpen && (
          <div className={`burger-menu__container ${isBurgerOpen ? 'open' : ''}`}>
            <form onSubmit={handleSearchSubmit} className="burger-menu__search-form">
              <div className="search-bar-container">
                <input
                  type="text"
                  placeholder="Rechercher..."
                  className="burger-menu__search-bar"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <button type="submit" className="burger-search-icon-button">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </form>
            <ul className="burger-menu__content">
              <li>
                <Link to="/académie" onClick={() => setIsBurgerOpen(false)}>
                  Académie
                </Link>
              </li>
              <li>
                <Link to="/actualités" onClick={() => setIsBurgerOpen(false)}>
                  Actualités
                </Link>
              </li>
              <li>
                <Link to="/apropos" onClick={() => setIsBurgerOpen(false)}>
                  À Propos
                </Link>
              </li>
            </ul>
          </div>
        )}

        <nav className="navbar">
          <div className="burger-menu" onClick={() => setIsBurgerOpen(!isBurgerOpen)}>
            <div className={`burger-bar ${isBurgerOpen ? 'open' : ''}`}></div>
            <div className={`burger-bar ${isBurgerOpen ? 'open' : ''}`}></div>
            <div className={`burger-bar ${isBurgerOpen ? 'open' : ''}`}></div>
          </div>

          <div className="navbar__logo">
            <Link to="/">
              <img src={logo} alt="Edufin" className="navbar__logo-image" />
            </Link>
          </div>

          <ul className={`navbar__nav-links ${isBurgerOpen ? 'navbar__nav-links--open' : ''}`}>
            <li>
              <Link to="/académie">
                <button className="navbar__li-button">Académie</button>
              </Link>
            </li>
            <li
              className="navbar__dropdown-container"
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
            >
              <Link to="/actualités">
                <button className="navbar__li-button">
                  Actualités <span className={`navbar__arrow ${showInvestDropdown ? 'navbar__arrow--up' : ''}`}>&#9660;</span>
                </button>
              </Link>
              {showInvestDropdown && window.innerWidth > 768 && (
                <ul className="navbar__dropdown-menu">
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('crypto')}>
                    <img src={cryptoIcon} alt="Cryptomonnaies" className="navbar__icon" /> Cryptomonnaies
                  </li>
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('bourse')}>
                    <img src={bourseIcon} alt="Bourse" className="navbar__icon" /> Bourse
                  </li>
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('guide')}>
                    <img src={guideIcon} alt="Guides" className="navbar__icon" /> Guides
                  </li>
                  <li className="navbar__dropdown-item" onClick={() => handleScrollTo('newsletter')}>
                    <img src={newsletterIcon} alt="Newsletter" className="navbar__icon" /> Newsletter
                  </li>
                </ul>
              )}
            </li>
            <li>
              <Link to="/apropos">
                <button className="navbar__li-button">À Propos</button>
              </Link>
            </li>
          </ul>

          <div className="navbar__right-section">
            <a href="https://edufin.podia.com/" target="_blank" rel="noopener noreferrer">
              <button className="navbar__signup-button">Inscription</button>
            </a>
            <button className="navbar__language-button">
              <FaGlobe /> FR/FR
            </button>
            <form onSubmit={handleSearchSubmit} className="navbar__search-form">
              <div className="search-bar-container">
                <input
                  type="text"
                  placeholder="Rechercher..."
                  className="navbar__search-bar"
                  value={searchQuery}
                  onChange={handleSearchChange}
                />
                <button type="submit" className="search-icon-button">
                  <FontAwesomeIcon icon={faSearch} />
                </button>
              </div>
            </form>
          </div>
        </nav>
      </div>
    </>
  );
};

export default Navbar;