import React from 'react';
import { Pie } from 'react-chartjs-2';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { formatCurrency } from '../../utils/formatters';

ChartJS.register(ArcElement, Tooltip, Legend);

const AssetDistribution = ({ assets }) => {
  const data = {
    labels: assets.map(asset => asset.name),
    datasets: [
      {
        data: assets.map(asset => asset.value),
        backgroundColor: [
          '#4F46E5', // Indigo
          '#10B981', // Emerald
          '#F59E0B', // Amber
          '#EF4444', // Red
          '#8B5CF6', // Purple
        ],
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        position: 'bottom',
        labels: {
          generateLabels: (chart) => {
            const datasets = chart.data.datasets;
            return chart.data.labels.map((label, index) => ({
              text: `${label} (${formatCurrency(datasets[0].data[index])})`,
              fillStyle: datasets[0].backgroundColor[index],
              index
            }));
          }
        }
      },
      tooltip: {
        callbacks: {
          label: (context) => {
            const value = context.raw;
            const total = context.dataset.data.reduce((a, b) => a + b, 0);
            const percentage = ((value / total) * 100).toFixed(1);
            return `${context.label}: ${formatCurrency(value)} (${percentage}%)`;
          }
        }
      }
    },
    maintainAspectRatio: false
  };

  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4 text-custom-blue">Distribution des Actifs</h2>
      <div className="h-64">
        <Pie data={data} options={options} />
      </div>
    </div>
  );
};

export default AssetDistribution;