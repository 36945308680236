import axios from 'axios';

const COINGECKO_API = 'https://api.coingecko.com/api/v3';
const RAPIDAPI_HOST = 'apidojo-yahoo-finance-v1.p.rapidapi.com';
const RAPIDAPI_KEY = '9d3ee6492emshea21ebe6fec182fp1ff554jsn3980d2388054'; // Remplacez par votre clé API RapidAPI

const INDICES = [
  { symbol: '^GSPC', name: 'S&P 500', country: 'US' },
  { symbol: '^FCHI', name: 'CAC 40', country: 'FR' },
  { symbol: '^FTSE', name: 'FTSE 100', country: 'UK' },
  { symbol: '^GDAXI', name: 'DAX', country: 'DE' },
  { symbol: '^N225', name: 'Nikkei 225', country: 'JP' }
];

export const api = axios.create({
  timeout: 10000,
});

export const getCryptoData = async () => {
  try {
    const response = await api.get(`${COINGECKO_API}/simple/price`, {
      params: {
        ids: 'bitcoin,ethereum,binancecoin,cardano,solana',
        vs_currencies: 'eur',
        include_24hr_change: true
      }
    });
    return transformCryptoData(response.data);
  } catch (error) {
    console.error('Error fetching crypto data:', error);
    throw error;
  }
};

export const getStockIndices = async () => {
  try {
    const promises = INDICES.map(index => {
      const options = {
        method: 'GET',
        url: `https://${RAPIDAPI_HOST}/market/v2/get-quotes`,
        params: {
          region: 'US',
          symbols: index.symbol
        },
        headers: {
          'x-rapidapi-key': RAPIDAPI_KEY,
          'x-rapidapi-host': RAPIDAPI_HOST
        }
      };
      return axios.request(options);
    });

    const responses = await Promise.allSettled(promises);
    return transformStockData(responses);
  } catch (error) {
    console.error('Error fetching stock data:', error);
    throw error;
  }
};

const transformCryptoData = (data) => {
  const cryptoMap = {
    bitcoin: { name: 'Bitcoin', symbol: 'BTC', icon: '/assets/btc.png' },
    ethereum: { name: 'Ethereum', symbol: 'ETH', icon: '/assets/eth.png' },
    binancecoin: { name: 'Binance Coin', symbol: 'BNB', icon: '/assets/bnb.png' },
    cardano: { name: 'Cardano', symbol: 'ADA', icon: '/assets/ada.png' },
    solana: { name: 'Solana', symbol: 'SOL', icon: '/assets/sol.png' }
  };

  return Object.entries(data).map(([id, values]) => ({
    ...cryptoMap[id],
    price: values.eur,
    change24h: values.eur_24h_change
  }));
};

const transformStockData = (responses) => {
  return INDICES.map((index, i) => {
    const response = responses[i];
    
    if (response.status === 'fulfilled' && response.value?.data?.quoteResponse?.result?.[0]) {
      const quote = response.value.data.quoteResponse.result[0];
      return {
        ...index,
        points: parseFloat(quote.regularMarketPrice) || 0,
        change: parseFloat(quote.regularMarketChangePercent) || 0
      };
    }
    
    // Return null if the API call failed
    return {
      ...index,
      points: 0,
      change: 0
    };
  });
};