// src/context/UserContext.js
import React, { createContext, useState, useContext } from 'react';

// Création du contexte utilisateur
const UserContext = createContext(null);

// Création d'un provider pour envelopper l'application
export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  return (
    <UserContext.Provider value={{ user, setUser }}>
      {children}
    </UserContext.Provider>
  );
};

// Hook personnalisé pour utiliser le contexte utilisateur
export const useUser = () => {
  return useContext(UserContext);
};
