import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

function Login() {
  const [formData, setFormData] = useState({ email: '', password: '' });
  const [errors, setErrors] = useState({ email: '', password: '' });
  const [isSubmitting, setIsSubmitting] = useState(false); // Nouvel état pour indiquer le chargement
  const navigate = useNavigate();

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Réinitialisation des erreurs
    let currentErrors = { email: '', password: '' };
    let valid = true;

    // Validation des champs Email et Mot de passe
    if (!formData.email) {
      currentErrors.email = "L'email est requis.";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      currentErrors.email = "L'email est invalide.";
      valid = false;
    }

    if (!formData.password) {
      currentErrors.password = "Le mot de passe est requis.";
      valid = false;
    } else if (formData.password.length < 6) {
      currentErrors.password = "Le mot de passe doit comporter au moins 6 caractères.";
      valid = false;
    }

    setErrors(currentErrors);

    if (valid) {
      setIsSubmitting(true); // Indiquer que le formulaire est en cours de soumission
      try {
        // Envoi des informations de connexion au backend
        const response = await axios.post('/api/auth/login', {
          email: formData.email,
          password: formData.password,
        });

        if (response.status === 200) {
          console.log("Redirection vers l'accueil réussie");
          navigate("/");
        }        
      } catch (error) {
        setErrors({
          email: '',
          password: 'Email ou mot de passe incorrect.',
        });
      } finally {
        setIsSubmitting(false); // Réinitialiser l'état de soumission
      }
    }
  };

  return (
    <div style={styles.page}>
      <div style={styles.container}>
        <h2 style={styles.title}>Connectez-vous</h2>
        <p style={styles.subtitle}>
          Pas encore inscrit ? <Link to="/Signup" style={styles.link}>Inscrivez-vous ici.</Link>
        </p>
        <form style={styles.form} onSubmit={handleSubmit}>
          <input
            type="email"
            name="email"
            placeholder="Email"
            style={styles.input}
            value={formData.email}
            onChange={handleChange}
            disabled={isSubmitting} // Désactiver les champs pendant la soumission
          />
          {errors.email && <p style={styles.error}>{errors.email}</p>}

          <input
            type="password"
            name="password"
            placeholder="Mot de passe"
            style={styles.input}
            value={formData.password}
            onChange={handleChange}
            disabled={isSubmitting} // Désactiver les champs pendant la soumission
          />
          {errors.password && <p style={styles.error}>{errors.password}</p>}

          <button type="submit" style={styles.button} disabled={isSubmitting}>
            {isSubmitting ? 'Connexion...' : 'Se connecter'} {/* Texte du bouton pendant la soumission */}
          </button>
          <Link to="/Contact" style={styles.link}>Problèmes de connexion ?</Link>
        </form>
      </div>
    </div>
  );
}

const styles = {
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    height: '100vh',
    background: 'linear-gradient(to bottom, #0f2027, #203a43, #2c5364)',
  },
  container: {
    background: 'white',
    padding: '20px',
    borderRadius: '10px',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.1)',
    textAlign: 'center',
  },
  title: {
    fontFamily: 'Arial, sans-serif',
    fontSize: '24px',
    marginBottom: '10px',
  },
  subtitle: {
    fontSize: '14px',
    marginBottom: '20px',
    color: '#666',
  },
  link: {
    color: '#007BFF',
    textDecoration: 'none',
    fontSize: '14px',
  },
  form: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    marginBottom: '10px',
    padding: '10px',
    fontSize: '14px',
    borderRadius: '5px',
    border: '1px solid #ccc',
  },
  button: {
    padding: '10px',
    fontSize: '16px',
    borderRadius: '5px',
    border: 'none',
    background: '#243EFF',
    color: 'white',
    cursor: 'pointer',
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginBottom: '10px',
  },
};

export default Login;
