import React, { useEffect, useRef, useState } from 'react';
import Counter from './Counter';
import './Statistics.css'; // Assurez-vous d'importer le fichier CSS

function Statistics2() {
  const [isVisible, setIsVisible] = useState(false);
  const statsRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        if (entries[0].isIntersecting) {
          setIsVisible(true);
          observer.disconnect();
        }
      },
      { threshold: 0.5 } // Ajustez le seuil pour qu'au moins 50% de la section soit visible
    );

    const currentRef = statsRef.current;
    if (currentRef) {
      observer.observe(currentRef);
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef);
      }
    };
  }, []);

  return (
    <section ref={statsRef} className="stats">
      <div className="statItem">
        {isVisible && <Counter end="34,99" duration="2" suffix="$" className="counter" start={isVisible} />}
        <p>par mois sans engagement</p>
      </div>
      <div className="statItem">
        {isVisible && <Counter end="300" duration="2" suffix="$" className="counter" start={isVisible} />}
        <p>par an avec engagement</p>
      </div>
      <div className="statItem">
        {isVisible && <Counter end="30" duration="2" prefix="+" suffix="%" className="counter" start={isVisible} />}
        <p>de performance de portefeuilles</p>
      </div>
    </section>
  );
}

export default Statistics2;