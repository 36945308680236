import React, { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import '../pages/Actualités.css'; // Importez le fichier CSS

const Actualités = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [hoveredCard, setHoveredCard] = useState(null);

  useEffect(() => {
    if (location.hash) {
      const element = document.getElementById(location.hash.substring(1));
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    if (location.state && location.state.scrollToId) {
      const element = document.getElementById(location.state.scrollToId);
      if (element) {
        element.scrollIntoView({ behavior: 'smooth' });
      }
    }
  }, [location]);

  useEffect(() => {
    const cards = document.querySelectorAll('.guideCard, .news-card, .ressources-card');
    const observer = new IntersectionObserver(entries => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          entry.target.classList.add('visible');
        }
      });
    });

    cards.forEach(card => {
      observer.observe(card);
    });

    return () => {
      cards.forEach(card => {
        observer.unobserve(card);
      });
    };
  }, []);

  const handleRedirect = () => {
    navigate('/académie');
    window.scrollTo(0, 0);
  };

  const handleMouseEnter = (index) => {
    setHoveredCard(index);
  };

  const handleMouseLeave = () => {
    setHoveredCard(null);
  };

  return (
    <div className="ActuBackground">
      <div className="ActuContainer">
        {/* En-tête */}
        <header className="header">
          <h1 className="title">Maîtrisez l'investissement avec Edufin</h1>
          <p className="subtitle">
            Découvrez nos guides et actualités pour maîtriser les investissements en cryptomonnaie et en bourse
          </p>
          <button className="primaryButton" onClick={handleRedirect}>
            Commencez dès maintenant
          </button>
        </header>

        {/* Actualités Crypto */}
        <section id="crypto" className="latestNewsSection">
          <h2 className="sectionTitle">Dernières Actualités Cryptomonnaies</h2>
          <div className="newsGrid">
            {/* News 1 */}
            <Link
              to="/articles/coinbase-chute-wallstreet"
              className={`guideCard ${hoveredCard === 2 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(2)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/coinbase-chute-wallstreet1.jpg" alt="Coinbase chute après Wall Street" className="image" />
              <p className="newsMeta">21 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
              <h3 className="newsTitle">Coinbase chute après des résultats inférieurs aux attentes de Wall Street</h3>
            </Link>
            {/* News 2 */}
            <Link
              to="/articles/hausse-bitcoin-renouveau-ou-mirage"
              className={`guideCard ${hoveredCard === 1 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(1)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/hausse-bitcoin-renouveau-ou-mirage1.jpg" alt="Hausse du Bitcoin" className="image" />
              <p className="newsMeta">15 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
              <h3 className="newsTitle">Hausse du Bitcoin : Vers un renouveau ou simple mirage pour les cryptomonnaies ?</h3>
            </Link>
            {/* News 3 */}
            <Link
              to="/articles/temasek-republic-blockchain"
              className={`guideCard ${hoveredCard === 0 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(0)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/temasek-republic-blockchain1.jpg" alt="Temasek et Republic s'associent" className="image" />
              <p className="newsMeta">8 Novembre 2024 | Baptiste Ferahian</p> {/* Ajoutez la date et l'auteur ici */}
              <h3 className="newsTitle">100 millions pour transformer la blockchain : Temasek et Republic s'associent</h3>
            </Link>
          </div>
          <div className="ActuButtonContainer">
            <Link to="/searchDropdownCrypto" className="ActuSecondaryButton">Voir plus d'articles</Link>
          </div>
        </section>

        {/* Actualités Bourse */}
        <section id="bourse" className="latestNewsSection">
          <h2 className="sectionTitle">Dernières Actualités Boursières</h2>
          <div className="newsGrid">
            {/* News 1 */}
            <Link
              to="/articles/ukraine-ukrenergo-suspend-paiements"
              className={`guideCard ${hoveredCard === 3 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(3)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/ukraine-ukrenergo-suspend-paiements1.png" alt="Ukrenergo suspend ses paiments" className="image" />
              <p className="newsMeta">13 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
              <h3 className="newsTitle">L'Ukraine : Ukrenergo suspend les paiements de sa dette verte de 825 millions de dollars en attente de restructuration</h3>
            </Link>
            {/* News 2 */}
            <Link
              to="/articles/stocks-hausse-obligations-baisse"
              className={`guideCard ${hoveredCard === 4 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(4)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/stocks-hausse-obligations-baisse1.png" alt="les marchés financiers réagissent à la réélection de Donald Trump" className="image" />
              <p className="newsMeta">5 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
              <h3 className="newsTitle">Stocks en Hausse, Obligations en Baisse : Réaction des Marchés après la Victoire de Trump</h3>
            </Link>
            {/* News 3 */}
            <Link
              to="/articles/boeing-greve-accord-salarial"
              className={`guideCard ${hoveredCard === 5 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(5)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/boeing-greve-accord-salarial1.png" alt="Boeing a proposé une augmentation salariale aux employés grévistes" className="image" />
              <p className="newsMeta">3 Novembre 2024 | Hugo Boulet</p> {/* Ajoutez la date et l'auteur ici */}
              <h3 className="newsTitle">Grève chez Boeing : Vers un Accord Salarial Historique</h3>
            </Link>
          </div>
          <div className="ActuButtonContainer">
            <Link to="/searchDropdownBourse" className="ActuSecondaryButton">Voir plus d'articles</Link>
          </div>
        </section>

        {/* Section Guide */}
        <section id="guide" className="guidesSection">
          <h2 className="sectionTitle">Guides pour débutants</h2>
          <div className="guidesGrid">
            {/* Guide 1 */}
            <a
              href="https://edufin.podia.com/a9ac8255-3567-42dd-b0cb-26800252d0ca" target="_blank" rel="noopener noreferrer"
              className={`guideCard ${hoveredCard === 6 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(6)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/dollar-crypto.webp" alt="Guide 1" className="image" />
              <h3 className="guideTitle">Guide cryptomonnaies</h3>
            </a>
            {/* Guide 2 */}
            <a
              href="https://edufin.podia.com/guide-complet-introduction-a-la-bourse-starter-pack" target="_blank" rel="noopener noreferrer"
              className={`guideCard ${hoveredCard === 7 ? 'guideCardHover' : ''}`}
              onMouseEnter={() => handleMouseEnter(7)}
              onMouseLeave={handleMouseLeave}
            >
              <img src="/assets/BourseImage.jpg" alt="Guide 2" className="image" />
              <h3 className="guideTitle">Guide bourse</h3>
            </a>
          </div>
        </section>
        {/* Section Newsletters */}
        <section id="newsletter" className="newsletterSection">
          <header className="header2">
            <h2 className="newsletterTitle">Abonnez-vous à notre Newsletter !</h2>
            <p className="subtitle2">
              Recevez les dernières actualités et analyses des marchés financiers directement dans votre boîte mail.
            </p>
            <a href="https://ca841cc5.sibforms.com/serve/MUIFAPuSy5Nx0Vchq3EVduGDudsQrDBzu6XkbzgXC8GgaT0W65N8nmNxWDgJvBSFf-DLKmSOmfmabcYxv7mMfkALgE7p6iFoa067WChDx3WqjXln2E3854fisQ0Yy6LT6O5j-NVBlFF-nDUVGRWrMHSLD6FZzLigJdKQlpgXh0Dqn1f2OVhFEQw9RTjtmf-bWePuPgLlxKqJ1z8x" className="primaryButton" target="_blank" rel="noopener noreferrer">
              S'abonner
            </a>
          </header>
        </section>
      </div>
    </div>
  );
}

export default Actualités;