import React from 'react';
import '../pages/Académie2.css'; // Importez le fichier CSS
import HeroSection from '../components/HeroSection2';
import Statistics2 from '../components/Statistics2';
import VisuelIphone from '../assets/Visuel-Iphone.png'; // Importez l'image

function Académie() {
  return (
    <div className="page">
      <div className="content">
        <HeroSection />
        <div className="container">
          {/* Section principale avec le titre et le CTA */}
          <header className="header">
            <h1 className="Académie-sectionTitle">Formez-vous à l'investissement avec une formation simple, complète et accessible</h1>
            <p className="subtitle">Formation complète pour tous les niveaux, à un prix imbattable !</p>
          </header>
          <Statistics2 />

          {/* Section: Plateforme (Dashboard) */}
          <section className="platformSection">
            <h2 className="Académie-sectionTitle">Simplifiez-vous la vie avec Edufin</h2>
            <p className="subtitle">
              Utilisez toute la puissance d’Edufin dans votre apprentissage et investissement pour décupler vos résultats.
            </p>
            <div className="platformImageContainer">
              <div className="platformImage">
                <img src={VisuelIphone} alt="Visuel Iphone" />
              </div>
              <div className="annotations">
                <p>Découvrez comment notre académie peut transformer votre approche de l'investissement. Avec des cours interactifs, des outils puissants et un soutien personnalisé, vous serez équipé pour atteindre vos objectifs financiers plus rapidement et plus efficacement. Rejoignez-nous et commencez votre parcours vers la réussite dès aujourd'hui !</p>
              </div>
            </div>
          </section>

          {/* Section: Les 3 plus grosses erreurs */}
          <section className="errorsSection">
            <h2 className="ErrorSectionTitle">Les 3 plus grosses erreurs que les gens font lorsqu’ils investissent</h2>
            <div className="errorsContainer">
              <div className="errorItem">
                <h3 className="errorTitle">1. Manque d'information claire</h3>
                <p className="errorText">
                  Les débutants sont souvent confrontés à une abondance d'informations complexes et contradictoires, ce qui rend difficile la prise de décision éclairée. Sans une compréhension claire des concepts de base, ils risquent de faire des choix hasardeux.
                </p>
              </div>
              <div className="errorItem">
                <h3 className="errorTitle">2. Manque de suivi</h3>
                <p className="errorText">
                  Sans un système de suivi efficace, il est difficile de mesurer les progrès ou de détecter les erreurs à temps. Cela peut conduire à une mauvaise gestion du portefeuille et à des pertes financières.
                </p>
              </div>
              <div className="errorItem">
                <h3 className="errorTitle">3. Manque de compréhension</h3>
                <p className="errorText">
                  Beaucoup d'investisseurs débutants manquent de connaissances sur les produits financiers qu'ils achètent. Cela peut entraîner des choix inappropriés, mal alignés avec leurs objectifs financiers, et accroître le risque global de leur portefeuille.
                </p>
              </div>
            </div>
          </section>

          {/* Section: Question & Accroche */}
          <section className="questionSection">
            <h2 className="questionTitle">Quels seront vos avantages si vous rejoignez l’académie Edufin ?</h2>
            <p className="questionText">
              Avec une plateforme dédiée pour vous, vous retrouverez les modules et bonus de formation avec un suivi complet de votre apprentissage, le suivi de vos investissements et performances, ainsi que notre portefeuille privé en totale transparence, sans compter un assistant dédié pour vous accompagner.
            </p>
            <a href="https://edufin.podia.com/" className="Académie-secondaryButton" target="_blank" rel="noopener noreferrer">
              Obtenir un accès à l’académie
            </a>
            <p className="socialProof">Plus de X personnes déjà accompagnées.</p>
          </section>

          {/* Section: Chiffres clés */}
          <section className="keyFiguresSection">
            <h2 className="keyFigures-sectionTitle">Chiffres clés</h2>
            <p className="keyFigureItem">X nombre de formations</p>
            <p className="keyFigureItem">X personnes accompagnées</p>
            <p className="keyFigureItem">X temps gagné avec le suivi de nos investissements</p>
            <p className="keyFigureItem">X performances</p>
          </section>
        </div>
      </div>
    </div>
  );
}

export default Académie;