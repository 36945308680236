import React from 'react';
import { useNavigate } from 'react-router-dom';

const Lerning = () => {
  const navigate = useNavigate();

  const handleRedirect = () => {
    navigate('/actualités');
    window.scrollTo(0, 0);
  };

  return (
    <section className="ctaSection">
      <div className="ctaContainer">
        <h1 className="ctaTitle">Developpez Vos Stratégies d'Investissement</h1>
        <p className="ctaSubtitle">
          Découvrez nos stratégies d'investissement et apprenez à gérer votre portefeuille pour maximiser vos rendements. Que vous soyez un investisseur débutant ou expérimenté, nos ressources et conseils vous aideront à prendre des décisions éclairées et à atteindre vos objectifs financiers.
        </p>
        <button className="primaryButton" onClick={handleRedirect}>
        Commencez à investir
        </button>
      </div>
    </section>
  );
};

export default Lerning;