import React from 'react';
import dayjs from 'dayjs';
import 'dayjs/locale/fr';
import { formatCurrency } from '../../utils/formatters';

const RecentTransactions = ({ transactions }) => {
  return (
    <div className="bg-white rounded-lg shadow-md p-6">
      <h2 className="text-2xl font-bold mb-4 text-custom-blue">Transactions Récentes</h2>
      <div className="overflow-x-auto">
        <table className="min-w-full">
          <thead>
            <tr className="border-b">
              <th className="text-left py-3">Date</th>
              <th className="text-left py-3">Type</th>
              <th className="text-left py-3">Actif</th>
              <th className="text-right py-3">Montant</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((transaction, index) => (
              <tr key={index} className="border-b">
                <td className="py-3">
                  {dayjs(transaction.date).locale('fr').format('DD MMMM YYYY')}
                </td>
                <td className="py-3">
                  <span className={`px-2 py-1 rounded-full text-sm ${
                    transaction.type === 'achat' ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'
                  }`}>
                    {transaction.type.charAt(0).toUpperCase() + transaction.type.slice(1)}
                  </span>
                </td>
                <td className="py-3">{transaction.asset}</td>
                <td className={`py-3 text-right ${
                  transaction.type === 'achat' ? 'text-green-600' : 'text-red-600'
                }`}>
                  {transaction.type === 'achat' ? '+' : '-'}{formatCurrency(transaction.amount)}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default RecentTransactions;