import useSWR from 'swr';
import { getCryptoData, getStockIndices } from '../services/api';

export const useMarketData = () => {
  const { data: cryptoData, error: cryptoError } = useSWR(
    'crypto',
    getCryptoData,
    { 
      refreshInterval: 30000, // Refresh every 30 seconds
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      errorRetryCount: 3
    }
  );

  const { data: stockData, error: stockError } = useSWR(
    'stocks',
    getStockIndices,
    { 
      refreshInterval: 60000, // Refresh every minute
      revalidateOnFocus: false,
      shouldRetryOnError: true,
      errorRetryCount: 3
    }
  );

  return {
    cryptoData,
    stockData,
    isLoading: !cryptoData && !stockData && !cryptoError && !stockError,
    isError: cryptoError || stockError
  };
};